import { Map } from "@mui/icons-material";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import PeopleIcon from "@mui/icons-material/People";
import { SvgIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const serverHost = "zanecraft.net";
const serverPort = 25565;

export default function Header() {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        fetch(`https://mcapi.us/server/status?ip=${serverHost}&port=${serverPort}`)
            .then(response => response.json())
            .then(data => setStatus(data))
            .catch(() => {});
    }, []);

    return (
        <header className="fixed top-0 z-50 w-full h-16 bg-bgcolor-light shadow-md dark:bg-dbgcolor-dark">
            <div className="flex flex-row justify-between gap-6 h-full max-w-screen-xl mx-auto px-4 font-display select-none sm:px-6 sm:gap-8">
                <div className="flex flex-row items-center gap-6 text-2xl sm:gap-8 sm:text-3xl">
                    <Link
                        className="font-semibold text-accent-light dark:text-accent-dark"
                        to="/"
                    >
                        zanecraft.net
                    </Link>
                </div>
                <div className="flex flex-row items-center gap-6 text-xl sm:gap-8">
                    {status == null ? <></> :
                    !status["online"] || !("now" in status["players"]) ?
                        <div className="flex flex-row items-center gap-2">
                            <span className="hidden sm:block">Offline</span>
                            <SvgIcon className="block sm:!hidden" component={CloudOffIcon} />
                        </div> :
                        <div className="flex flex-row items-center gap-2">
                            <span className="font-semibold">{status["players"]["now"]}</span>
                            <span className="hidden sm:block">Spieler</span>
                            <SvgIcon className="block sm:!hidden" component={PeopleIcon} />
                        </div>}
                        <div className="flex flex-row items-center gap-2">
                            <a
                                className="transition-colors hover:text-accent-light dark:hovertext-accent-dark"
                                href="https://map.zanecraft.net"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="hidden sm:block">Map</span>
                                <SvgIcon className="block sm:!hidden" component={Map} />
                            </a>
                        </div>
                </div>
            </div>
        </header>
    );
}
